<template>
  <FieldWrapper :field="field">
    <button
      class="btn btn-primary"
      :class="{ 'spinner spinner-white spinner-right': running }"
      :disabled="(running && !cancelable) || isDisabled"
      @click="onAction"
    >
      {{ cancelable ? $t("formHelper.cancel") : getSnippet(field.buttonLabel) }}
    </button>
  </FieldWrapper>
</template>

<script>
import { base } from "@/components/Tools/FormHelper/Helper/mixins";
import FieldWrapper from "@/components/Tools/FormHelper/Components/FieldWrapper";
import ApiService from "@/core/services/api.service";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
  components: { FieldWrapper },
  mixins: [base],
  props: {},
  data() {
    return {
      disabled: false,
      running: false,
      cancelToken: undefined,
      ActionApiService: undefined
    };
  },
  computed: {
    // Get api tokens from store
    ...mapGetters(["apiToken", "apiTokenPartner"]),
    // Return if request is running and cancelable
    cancelable: function () {
      return this.running && this.cancelToken !== undefined;
    }
  },
  mounted() {
    // Set default headers for ApiService
    this.ActionApiService = ApiService;
    this.ActionApiService.setHeader("x-api-key", this.apiToken);
    this.ActionApiService.setHeader("x-partner-key", this.apiTokenPartner);
  },
  methods: {
    // Fires on button click
    async onAction() {
      if (this.field.action === undefined) {
        // Fire toast for information
        this.noAction();
      }
      if (this.running && !this.cancelable) {
        // If action is running but not cancelable, return
        return;
      } else if (this.running && this.cancelable) {
        // If request is running and cancelable
        // Cancel request, unset cancelToken
        this.cancelToken.cancel();
        this.cancelToken = undefined;
        // Fire notification
        this.requestCanceled();
        return;
      }
      // Disable button
      this.running = true;
      if (typeof this.field.action === "function") {
        // If action is a js function, execute it
        await this.field.action();
      } else if (typeof this.field.action === "object") {
        // If action is request config
        await this.fireRequest();
      }
      // Enable button
      this.running = false;
    },
    async fireRequest() {
      // Create cancel token from axios
      this.cancelToken = axios.CancelToken.source();
      // Add cancel token to request headers
      const headers = Object.assign(
        { cancelToken: this.cancelToken.token },
        this.field.action.headers ?? {}
      );
      // Send request by request method
      switch (this.field.action.method) {
        case "GET":
          await this.requestGET(headers);
          break;
        case "POST":
          await this.requestPOST(headers);
          break;
        case "PUT":
          await this.requestPUT(headers);
          break;
        case "DELETE":
          await this.requestDELETE(headers);
          break;
      }
      // Unset cancel token
      this.cancelToken = undefined;
    },
    async requestGET(headers) {
      let params = new URLSearchParams(
        this.field.action.params ?? {}
      ).toString();
      await this.ActionApiService.get(
        this.field.action.url,
        "?" + params,
        headers
      )
        .then(response => {
          this.$emit("action", { field: this.field, data: response.data });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async requestPOST(headers) {
      await this.ActionApiService.post(
        this.field.action.url,
        this.field.action.params,
        headers
      )
        .then(response => {
          this.$emit("action", { field: this.field, data: response.data });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async requestPUT(headers) {
      await this.ActionApiService.put(
        this.field.action.url,
        this.field.action.params,
        headers
      )
        .then(response => {
          this.$emit("action", { field: this.field, data: response.data });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    async requestDELETE(headers) {
      await this.ActionApiService.delete(this.field.action.url, headers)
        .then(response => {
          this.$emit("action", { field: this.field, data: response.data });
        })
        .catch(error => {
          this.$error(error);
        });
    },
    noAction() {
      // Fire toast for information
      this.$toast.fire({
        icon: "error",
        title: this.$t("formHelper.noActionDefined")
      });
    },
    requestCanceled() {
      // Fire toast for information
      this.$toast.fire({
        icon: "info",
        title: this.$t("formHelper.requestCanceled")
      });
    }
  }
};
</script>
